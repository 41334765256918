<template>
  <div ref="chart" style="width: 100%; height: 100%" v-resize="onResize"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "CapabilityCharts",
  props: {
    title: {
      type: String,
      default: "",
    },
    dataArr: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      let adapterFont = (this.$refs.chart.offsetHeight / 100) * 3;
      const that = this;
      const toolTipWidth = adapterFont * 23;
      const toolTipHeight = adapterFont * 12;
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          showContent: true,
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: adapterFont * 1.2,
          },
          extraCssText: `width: ${toolTipWidth} + px; white-space:pre-wrap;`,
          // 为了解决部分tooltip被遮挡
          position: function (pos, params, dom, rect, size) {
            var obj = {};
            obj[["top", "bottom"][+(pos[1] < size.viewSize[1] / 2)]] =
              pos[1] < size.viewSize[1] / 2
                ? size.viewSize[1] - pos[1] - toolTipHeight
                : pos[1] - toolTipHeight;

            // 为了解决行业经验汇总tooltip显示不全问题
            if (that.dataArr.map((item) => item.typeName)?.length > 4) {
              // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] =
                pos[0] < size.viewSize[0] / 2
                  ? size.viewSize[0] - pos[0] - toolTipWidth
                  : pos[0] - toolTipWidth;
            } else {
              obj["left"] = pos[0] - toolTipWidth;
            }
            return obj;
          },
        },
        legend: {
          orient: "horizontal",
          icon: "circle",
          right: "3%",
          itemWidth: adapterFont / 1.2,
          itemHeight: adapterFont / 1.2,
          top: "0",
          itemGap: 5,
          textStyle: { fontSize: adapterFont / 1.2 },
          data: ["对应人数", "对应CV人数", "对应半年内更新过CV人数"],
        },
        grid: {
          top: "19%",
          left: "40",
          right: "3%",
          bottom: "25%",
          containLabel: false,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 25,
              height: 30,
              width: adapterFont * 20,
              overflow: "truncate",
              // overflow: "hidden",
              // ellipsis: "...",
              fontSize: adapterFont * 1.2,
            },
            data: this.dataArr.map((item) => item.typeName),
          },
        ],
        yAxis: [
          {
            name: "人数",
            type: "value",
            position: "left",
            alignTicks: true,
            minInterval: 1,
            axisLine: {
              show: true,
            },
            nameTextStyle: {
              fontSize: adapterFont * 1.2,
            },
            axisLabel: {
              formatter: "{value}",
              interval: 0,
              fontSize: adapterFont * 1.2,
            },
          },
        ],
        series: [
          {
            name: "对应人数",
            type: "bar",
            barWidth: this.title == "行业经验汇总" ? 22 : "",
            itemStyle: {
              color: this.config.countType,
            },
            data: this.dataArr.map((item) => item.countType),
            label: {
              show: true,
              color: this.config.countType,
              position: "top",
              formatter: "{c}",
              distance: 1,
              textStyle: {
                fontSize: adapterFont * 1.2,
                color: "rgb(142 144 151)",
                fontWeight: 400,
              },
            },
          },
          {
            name: "对应CV人数",
            type: "line",
            itemStyle: {
              color: this.config.countCv,
            },
            data: this.dataArr.map((item) => item.countCv),
          },
          {
            name: "对应半年内更新过CV人数",
            type: "line",
            itemStyle: {
              color: this.config.countCvHalfYear,
            },
            data: this.dataArr.map((item) => item.countCvHalfYear),
          },
        ],
      });
    },
    onResize() {
      this.chart.resize();
    },
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
    dataArr: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    /*this.chart.on("click",({ dataIndex }) => {
      this.$emit("itemClick", this.config[dataIndex].key);
    });*/
  },
};
</script>
