<template>
  <div class="card">
    <div class="header">
      <span>
        <slot name="left" :scope="title">
          <CardTitle v-if="title" :title="title" :size="size" />
        </slot>
      </span>
      <span class="icon-after-title">
        <slot name="icon"></slot>
      </span>
      <span><slot name="center"></slot></span>
      <span><slot name="right"></slot></span>
    </div>
    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import CardTitle from "./CardTitle.vue";
export default {
  name: "Card",
  components: { CardTitle },
  props: {
    title: String,
    size: String,
  },
};
</script>

<style lang="less" scoped>
.card {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    .icon-after-title {
      display: inline-block;
      line-height: 4px;
      margin-left: 10px;
    }
  }
  .body {
    flex: 1;
  }
}
</style>
