<template>
  <div class="rm-staff-container">
    <div class="tab">
      <a-tabs v-model:activeKey="activeKey" @change="handleChangeTab">
        <a-tab-pane key="1" tab="人员看板"></a-tab-pane>
        <a-tab-pane key="2" tab="Capability管理"></a-tab-pane>
      </a-tabs>
      <!-- 头部数据统计 -->
      <div class="header">
        <div v-if="activeKey === '1'">
          <span>在职人员总数：</span>
          <span class="link">{{ total }}</span>
        </div>
        <div v-if="activeKey === '2'">
          在职人数:
          <span class="link mr12">{{
            personInfo.countStaff
          }}</span>
          简历人数:
          <span class="link mr12">{{
            personInfo.countCv
          }}</span>
          近半年更新简历人数:
          <span class="link mr12">{{
            personInfo.countCvHalfYear
          }}</span>
        </div>
        <div class="feature-line">
          <a-button
            type="primary"
            @click="handleDetail"
            v-auth="['system:staffBoard:personList']"
            >人员列表</a-button
          >
        </div>
      </div>
    </div>
    <section class="rm-staff-board">
      <!-- 筛选项 -->
      <div class="mt12 mb12">
        <FilterLayout
          @doReset="resetForm"
          @doSearch="doSearch"
          @doCollapseEffect="getChartContainerStyle"
          ref="filterLayoutRef"
          @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible"
          :isShowMoreFlag="isShowMoreList"
        >
          <template #customform>
            <a-form
                ref="formRef"
                class="form rms-form"
                layout="vertical"
                :model="formData"
            >
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index<=4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formData[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formData[item.inputDataType]"
                          placeholder="请选择"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='4'">
                      <a-radio-group
                          v-model:value="formData[item.inputDataType]"
                          button-style="solid"
                          class="btnRadio"
                      >
                        <a-radio-button
                            :value="item.value"
                            v-for="(item, index) in getOptions(item.inputDataType)"
                            :key="index"
                        >{{ item.label }}</a-radio-button>
                      </a-radio-group>
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formData[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                          :tag-count="item.inputDataType==='gradeList'?3:1"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
              <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
                <template v-for="(item,index) in queryList" :key="index">
                  <template v-if="index>4">
                    <a-form-item :label="item.code" name="name" class="rms-form-item">
                      <template v-if="item.type==='2'">
                        <a-select
                            v-model:value="formData[item.inputDataType]"
                            :options="getOptions(item.inputDataType)"
                            placeholder="请选择"
                            allowClear
                            show-search
                        >
                        </a-select>
                      </template>
                      <template v-if="item.type==='3'">
                        <a-input
                            v-model:value="formData[item.inputDataType]"
                            placeholder="请选择"
                            allowClear
                            autocomplete="off"
                        />
                      </template>
                      <template v-if="item.type==='4'">
                        <a-radio-group
                            v-model:value="formData[item.inputDataType]"
                            button-style="solid"
                            class="btnRadio"
                        >
                          <a-radio-button
                              :value="item.value"
                              v-for="(item, index) in getOptions(item.inputDataType)"
                              :key="index"
                          >{{ item.label }}</a-radio-button>
                        </a-radio-group>
                      </template>
                      <template v-if="item.type==='5'">
                        <SelectWithAll
                            v-model:value="formData[item.inputDataType]"
                            placeholder="请选择"
                            mode="multiple"
                            showArrow
                            :maxTagCount="1"
                            allowClear
                            :options="getOptions(item.inputDataType)"
                            @change="getSelectChange(item.inputDataType)"
                            :tag-count="item.inputDataType==='gradeList'?3:1"
                        >
                        </SelectWithAll>
                      </template>
                    </a-form-item>
                  </template>
                </template>
              </MoreFormList>
            </a-form>
          </template>
        </FilterLayout>
      </div>
      <!-- 图表模块 -->
      <div :style="chartContainerStyle" class="chartContainer">
        <div class="content" v-if="activeKey === '1'">
          <div
            class="column"
            style="width: calc(50% - 5px); margin-right: 10px"
          >
            <Card
              style="height: calc(50% - 5px); margin-bottom: 10px"
              title="人员状态"
            >
              <template #icon>
                <a-radio-group
                  button-style="solid"
                  size="small"
                  class="staff-status-radio"
                  v-model:value="staffStatusType"
                >
                  <a-radio-button
                    v-for="(item, index) in staffStatusOptions"
                    :value="item.value"
                    :key="index"
                    >{{ item.label }}</a-radio-button
                  >
                </a-radio-group>
              </template>
              <StaffPieChart
                :data="
                  staffStatusType === '1' ? staffPieData : staffSummaryData
                "
                @itemClick="(val) => handleDetail({ status: val })"
              />
            </Card>
            <Card style="height: calc(50% - 5px)" title="Availability">
              <AvailabilityPieChart
                :config="availabilityConfig"
                :data="availabilityData"
                @itemClick="(val) => handleDetail({ availability: val })"
              />
            </Card>
          </div>
          <div class="column" style="width: calc(50% - 5px)">
            <Card
              style="height: calc(50% - 5px); margin-bottom: 10px"
              title="任务类型"
            >
              <TaskBarChart
                :config="taskTypeConfig"
                :data="taskTypeData"
                @itemClick="(val) => handleDetail({ taskType: val })"
              />
            </Card>
            <Card style="height: calc(50% - 5px)" title="调令类型">
              <AssignmentTypeBarChart
                :config="assignmentTypeConfig"
                :data="assignmentTypeData"
                @itemClick="(val) => handleDetail({ assignmentType: val })"
              />
            </Card>
          </div>
        </div>
        <CapabilityManagement
          class="content"
          v-if="activeKey === '2'"
          @handle-detail="handleDetail"
          :capabilityChartData="capabilityChartData"
          :capabilityChartColors="capabilityChartColors"
        ></CapabilityManagement>
      </div>
      <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
    </section>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  reactive,
  onActivated,
  nextTick,
  watch,
  getCurrentInstance,
} from "vue";
import { useRouter } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import Card from "@/components/Card";
import StaffPieChart from "./components/StaffPieChart";
import AvailabilityPieChart from "./components/AvailabilityPieChart";
import TaskBarChart from "./components/TaskBarChart";
import AssignmentTypeBarChart from "./components/AssignmentTypeBarChart";
import {
  dictionaryOpt,
  dictionaryOptions,
  dictionaryOptionsId,
  handleJtpFilter,
} from "@/utils/common";
import { cloneDeep,debounce } from "lodash";
import CapabilityManagement from "@/views/home/ResourceManagement/RM/Capability/CapabilityManagement";
import { getValueAndOps } from "@/utils/orgSelectData";

import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'

export default defineComponent({
  name: "staffBoard",
  components: {
    Card,
    StaffPieChart,
    TaskBarChart,
    AvailabilityPieChart,
    AssignmentTypeBarChart,
    CapabilityManagement,
    MoreFormList,
    FormModalBox
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    const total = ref(0);
    const orgOpsObj = reactive({
      orgTwoOps: [],
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: [],
    });
    const orgTwoOps = ref([]);
    const orgFiveOneOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const staffPieData = ref([]);
    const availabilityData = ref({});
    const availabilityConfig = ref([]);
    const taskTypeData = ref({});
    const taskTypeConfig = ref([]);
    const assignmentTypeData = ref({});
    const assignmentTypeConfig = ref([]);
    const filterLayoutRef = ref();
    const formRef = ref();
    const isShowCollapseBtn = ref(false);
    const show = ref(false);
    const staffGradeOps = ref([]);
    const statusOps = ref([]);
    const skillListOps = ref([]);
    let withJtpGradeOps;
    let withoutJtpGradeOps;
    let initData = {
      orgTwoList: [],
      orgFiveOneList: [],
      orgFourOneOneList: [],
      orgThreeTwoList: [],
      withJTP: "0",
      gradeList: [],
      statusList: [],
      roleList: [],
      skillList: [],
    }
    const formData = reactive({...initData});
    const roleOps = ref([]);
    const radioOptions = ref([
      { label: "With JTP", value: "1" },
      { label: "Without JTP", value: "0" },
    ]);

    const staffStatusType = ref("1");
    const staffStatusOptions = ref([
      { label: "Detail", value: "1" },
      { label: "Summary", value: "2" },
    ]);

    const activeKey = ref("1");
    let currentParams = {};
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = cloneDeep(formData);
    };

    // capability相关 start
    const personInfo = ref({
      countCv: 0,
      countCvHalfYear: 0,
      countStaff: 0,
    });
    const getPersonNumInfo = (currentParams) => {
      $api.getPersonNum(currentParams).then((res) => {
        personInfo.value = res.data;
      });
    };
    // capability相关 end

    const handleChangeTab = () => {
      show.value = false;
      filterLayoutRef.value.initFilterLayout();
      queryList.value=[]
      //初始化formData
      Object.assign(formData, initData)
      resetForm();
      closeMoreForm()
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    };
    const doSearch = () => {
      updateParams();
      if (activeKey.value === "1") {
        getStaffBoardChartsInfo(currentParams);
      } else {
        getCapabilityBoardChartsInfo(currentParams);
        //rm-644添加人员的重新查询
        getPersonNumInfo(currentParams);
      }
    };

    const staffSummaryData = ref([]);

    const getStaffBoardChartsInfo = (currentParams) => {
      $api.getStaffCircleNum(currentParams).then((res) => {
        total.value = res.data.total;
        staffPieData.value = res.data?.staffStatusList || [];
      });
      $api.getWorkTaskCount(currentParams).then((res) => {
        taskTypeData.value = res.data.reduce((obj, { taskType, countTask }) => {
          obj[taskType] = countTask;
          return obj;
        }, {});
      });
      $api.getAvailabilityStatistics(currentParams).then((res) => {
        availabilityData.value = res.data.reduce(
          (obj, { availability, countAvailability }) => {
            obj[availability] = countAvailability;
            return obj;
          },
          {}
        );
      });
      $api.getAssignmentTypeStatistics(currentParams).then((res) => {
        assignmentTypeData.value = res.data.reduce(
          (obj, { assignmentType, countAssignmentType }) => {
            obj[assignmentType] = countAssignmentType;
            return obj;
          },
          {}
        );
      });
      $api.getStaffSummaryNum(currentParams).then((res) => {
        total.value = res.data.total;
        staffSummaryData.value = res.data?.staffStatusList || [];
      });
    };
    const capabilityChartData = ref({
      skills: [],
      roles: [],
      certifications: [],
      industryLines: [],
    });
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }

    const capabilityChartColors = ref({
      skills: {
        countType: "#d6effa",
        countCv: "#eebb60",
        countCvHalfYear: "#6269c8",
      },
      roles: {
        countType: "#cbeae1",
        countCv: "#eebb60",
        countCvHalfYear: "#6269c8",
      },
      certifications: {
        countType: "#e4e0ff",
        countCv: "#eebb60",
        countCvHalfYear: "#6269c8",
      },
      industryLines: {
        countType: "#ffe0d2",
        countCv: "#eebb60",
        countCvHalfYear: "#6269c8",
      },
    });
    const getCapabilityBoardChartsInfo = (currentParams) => {
      $api.getCapabilityChartCollect(currentParams).then((res) => {
        capabilityChartData.value = res.data;
      });
    };
    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formData, initData)
      updateParams();
      doSearch();
      getOrgList();
      getDeptSkillOps();
    };
    const getTaskType = () => {
      $api.getWorkTaskComboBox().then((res) => {
        taskTypeConfig.value = (res.data?.taskType || []).map(
          ({ dictCode, dictValue, dictRemark }) => ({
            key: dictCode,
            label: dictValue,
            color: dictRemark,
          })
        );
      });
    };

    // 获取初始org下拉内容
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const {
          orgFourOneOneList,
          orgTwoList,
          orgFiveOneList,
          orgThreeTwoList,
        } = res.data;
        orgOpsObj.orgTwoOps = dictionaryOpt(orgTwoList);
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      });
    };

    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgTwoList: formData.orgTwoList,
        orgFiveOneList: formData.orgFiveOneList,
        orgThreeTwoList: formData.orgThreeTwoList,
        orgFourOneOneList: formData.orgFourOneOneList,
      };
      const { orgsValue, orgOpsList } = getValueAndOps(
        orgAllList.value,
        formOrgData,
        orgOpsObj,
        type
      );
      Object.assign(formData, orgsValue);
      Object.assign(orgOpsObj, orgOpsList);
      if (activeKey.value === "2") getDeptSkillOps();
    };

    const handleDetail = ({
      status,
      taskType,
      availability,
      assignmentType,
    }) => {
      if (staffStatusType.value === "2") return;
      router.push({
        path: "/rmBoard/staffInfo",
        query: {
          status,
          taskType,
          availability,
          assignmentType,
          orgTwoList: currentParams.orgTwoList.join(),
          orgFiveOneList: currentParams.orgFiveOneList.join(),
          orgThreeTwoList: currentParams.orgThreeTwoList.join(),
          orgFourOneOneList: currentParams.orgFourOneOneList.join(),
          gradeList: currentParams.gradeList.join(),
          roleList: currentParams.roleList.join(),
          withJTP: currentParams.withJTP,
          positionStatus: 1,
        },
      });
    };
    const getSelectOptions = () => {
      //数据字典
      $api
        .getDictionaryList({
          params: {
            dictType: "assignmentType,availability,grade,role,staffStatus",
          },
        })
        .then((res) => {
          assignmentTypeConfig.value = (res.data.assignmentType || []).map(
            ({ dictValue, dictCode, dictRemark }) => ({
              key: dictCode,
              label: dictValue,
              color: dictRemark,
            })
          );
          availabilityConfig.value = (res.data.availability || []).map(
            ({ dictValue, dictCode, dictRemark }) => ({
              key: dictCode,
              label: dictValue,
              color: dictRemark,
            })
          );
          // staffGradeOps.value = dictionaryOptions(res.data.grade);
          withJtpGradeOps = cloneDeep(dictionaryOptions(res.data.grade));
          withoutJtpGradeOps = handleJtpFilter(withJtpGradeOps);
          staffGradeOps.value = withoutJtpGradeOps;
          statusOps.value = dictionaryOptions(res.data.staffStatus);
          roleOps.value = dictionaryOptions(res.data.role);
        });
    };
    const getDeptSkillOps = () => {
      const data = {
        orgTwoList: formData.orgTwoList,
        orgFiveOneList: formData.orgFiveOneList,
        orgThreeTwoList: formData.orgThreeTwoList,
        orgFourOneOneList: formData.orgFourOneOneList,
      };
      $api.getDeptRelativeSkills(data).then((res) => {
        skillListOps.value = dictionaryOptionsId(res.data);
        if (formData.skillList?.length > 0) {
          filterSelectedSkills();
        }
      });
    };
    const filterSelectedSkills = () => {
      const skillId = [];
      skillListOps.value.forEach((item) => skillId.push(item.value));
      formData.skillList = formData.skillList.filter((item) =>
        skillId.includes(item)
      );
    };

    const chartContainerStyle = ref({
      height: "100%",
    });
    let contentHeightLock = false;
    const isShowHeight = ref(true)
    const getChartContainerStyle = () => {
      if (activeKey.value === "1") {
        isShowHeight.value = !isShowHeight.value
      }
      nextTick(() => {
        if (!document.querySelector(".chartContainer")) return;
        const chartContainerHeight =
            window.innerHeight -
            document.querySelector(".chartContainer").getBoundingClientRect().top;
        chartContainerStyle.value = {
          height: chartContainerHeight - 5 - 24 + "px",
        };
        console.log(chartContainerHeight, 'chartContainerHeight')
        if(isShowHeight.value && activeKey.value === "1"){
          document.querySelector(".content").style.height =
              chartContainerHeight - 10 + "px";
        } else if(!isShowHeight.value && activeKey.value === "1") {
          document.querySelector(".content").style.height =
              chartContainerHeight - 10 + "px"
        }
        if (!contentHeightLock && activeKey.value === "1") {
          // 人员看板保证第一屏不要滚动
          contentHeightLock = true;
          document.querySelector(".content").style.height =
              chartContainerHeight - 10 + "px";
        } else if (activeKey.value === "2") {
          contentHeightLock = false;
        }
      });
    };
    // 处理多选框撑开导致的高度变化
    const { proxy } = getCurrentInstance();
    const orgAllList = ref([]);
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data;
      });
    };

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'resourceManagement',
        secondMenu: activeKey.value==='1'?'employeeManagementOne':'employeeManagementTwo',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgTwoList':
          return orgOpsObj.orgTwoOps
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'gradeList':
          return staffGradeOps.value
        case 'withJTP':
          return radioOptions.value
        case 'statusList':
          return statusOps.value
        case 'roleList':
          return roleOps.value
        case 'skillList':
          return skillListOps.value
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      switch (data) {
        case 'orgTwoList':
          return orgThreeTwoChange('orgTwo')
        case 'orgThreeTwoList':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOneList':
          return orgThreeTwoChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getSelectOptions();
      getDeptSkillOps();
      getOrgList();
      getTaskType();
      doSearch();
      getPersonNumInfo(formData);
      getOrgRelatedList();
      // 处理多选框撑开导致的高度变化
      // proxy.$EventBus.on("change-select-height", () => {
      //   if (!proxy._.isDeactivated) {
      //     getChartContainerStyle();
      //   }
      // });
      getChartContainerStyle();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    onUnmounted(() => {
      proxy.$EventBus.off("change-select-height");
    });

    watch(
      () => activeKey.value,
      () => {
        isShowHeight.value = true
        getChartContainerStyle();
      }
    );

    onActivated(() => {
      // doSearch();
    });
    watch(
      () => formData.withJTP,
      (val) => {
        if (withoutJtpGradeOps && withJtpGradeOps)
          staffGradeOps.value =
            val === "0" ? withoutJtpGradeOps : withJtpGradeOps;
        if (formData.gradeList.length) {
          formData.gradeList = handleJtpFilter(formData.gradeList);
        }
      },
      { immediate: true, deep: true }
    );
    return {
      total,
      handleDetail,
      initData,
      formData,
      orgTwoOps,
      orgFiveOneOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      doSearch,
      staffPieData,
      availabilityData,
      availabilityConfig,
      taskTypeData,
      taskTypeConfig,
      assignmentTypeConfig,
      assignmentTypeData,
      resetForm,
      formRef,
      show,
      isShowCollapseBtn,
      updateParams,
      radioOptions,
      staffGradeOps,
      getSelectOptions,
      roleOps,
      chartContainerStyle,
      getChartContainerStyle,
      activeKey,
      personInfo,
      getPersonNumInfo,
      statusOps,
      skillListOps,
      handleChangeTab,
      getStaffBoardChartsInfo,
      getCapabilityBoardChartsInfo,
      capabilityChartData,
      capabilityChartColors,
      filterLayoutRef,
      getOrgRelatedList,
      orgAllList,
      orgOpsObj,
      getDeptSkillOps,
      filterSelectedSkills,
      staffSummaryData,
      staffStatusOptions,
      staffStatusType,
      isShowHeight,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>

<style lang="less" scoped>
// 大标题的tab样式
@import "~@/style/newTitleTab.less";

.rm-staff-container {
  margin: 0 16px 16px;
  width: calc(100% - 20px);
  // height: calc(100vh - 80px - 32px);
  // overflow-y: auto;
  .tab {
    display: flex;
    justify-content: space-between;
    .header {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      gap: 0.5vw;
      //margin-top: 1.2vw;
      margin-right: 1.2vw;
      align-items: center;
    }
  }
  .mt3 {
    margin-top: 3px;
  }
  .box {
    height: 160px;
  }
  .rm-staff-board {
    padding: 0 24px 24px;
    //height: calc(100% - 50px);
    background: white;
    display: flex;
    flex-direction: column;
    .btnRadio {
      width: 100%;
      display: flex;
      .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
      }
    }
    .chartContainer {
      overflow: auto;
      padding: 3px 3px 3px 3px;
      .staff-status-radio {
        width: 180px;
        text-align: center;

        :deep(.ant-radio-button-wrapper) {
          width: 50%;
        }
      }
    }
    .content {
      // height: 95%;
      display: flex;
      flex: 1;
      .column {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}
</style>
