<template>
  <div
    ref="chart"
    style="width: 100%; height: 100%"
    v-resize="adapterScreen"
  ></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "StaffPieChart",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      this.chart.setOption({
        avoidLabelOverlap: false,
        grid: {
          containLabel: true,
        },
        color:
          this.data?.length > 2
            ? [
                "#85EEBF",
                "#FBE9D4",
                "#BCB1FF",
                "#D7D0FF",
                "#B0DFD2",
                "#C9EAF8",
                "#5A99D4",
                "#EA9D59",
                "#7CCAB4",
              ]
            : ["#FDCD46", "#5A99D4"],
        series: [
          {
            type: "pie",
            radius: "72%",
            center: ["38%", "50.5%"],
            data: this.data.map(({ countStatus, status }) => ({
              value: countStatus === 0 ? null : countStatus,
              name: status,
            })),
            label: {
              normal: {
                formatter: "{b} {c}人，{d}%",
              },
            },

            textStyle: { color: "#787878", fontSize: "16" },
            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              scale: true,
              scaleSize: 10,
            },
          },
        ],
      });
    },
    // 适配
    adapterScreen() {
      let adapterFont = (this.$refs.chart.offsetWidth / 100) * 3;
      const adpOption = {
        tooltip: {
          trigger: "item",
          textStyle: {
            fontSize: adapterFont * 0.55,
          },
          formatter: (params) => {
            const str =
              "status：" +
              params.data.name +
              "</br>" +
              "人数：" +
              params.data.value +
              "</br>" +
              "所占总人数百分比：" +
              params.percent +
              "%" +
              "</br>";
            return str;
          },
        },
        legend: {
          show: true,
          orient: "vertical",
          right: 10,
          top: "1%",
          icon: "circle",
          itemWidth: adapterFont / 2.5,
          itemHeight: adapterFont / 2.5,
          itemGap: 5,
          textStyle: { fontSize: adapterFont / 2 },
          selectedMode: true,
          formatter: function (name) {
            const explainTags = ["Management", "Long-term Leave"];
            return explainTags.includes(name) ? name + `  �` : name;
          },
          tooltip: {
            show: true,
            formatter: function (valueObj) {
              const name = valueObj.name;
              if (name === "Management") {
                return "Management含Management和COE";
              } else if (name === "Long-term Leave") {
                return "Long-term Leave含No Pay Leave和Maternity Leave ";
              } else {
                return "";
              }
            },
          },
        },
        series: [
          {
            label: {
              fontSize: adapterFont / 1.8,
            },
            textStyle: { fontSize: adapterFont / 1.8 },
            emphasis: {
              itemStyle: {
                shadowBlur: adapterFont / 2,
                shadowOffsetX: 0,
              },
            },
          },
        ],
      };
      this.chart.setOption(adpOption);
      this.chart.resize();
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.adapterScreen();
    this.chart.on("click", (params) => {
      this.$emit("itemClick", params.data.name);
    });
  },
};
</script>
