<template>
  <div class="capability-charts-content">
    <div class="column mt3">
      <Card class="w4h6" title="部门技能汇总">
        <img class="zoom-img" @click="zoomEchart('1')" src="@/assets/iconfont/zoom.png" alt="zoom" />
        <template #icon>
          <a-popover placement="topLeft">
            <template #content>
              <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                <li>若未筛选“部门技能”，该图表展示过滤后排名前十的技能</li>
                <li>若筛选“部门技能”&lt;10，则图表优先左侧展示符合筛选条件的人数，且将满足以上条件人员对应的其他技能按照排名在图表中右侧补位</li>
                <li>若筛选“部门技能”&ge;10，则仅展示排序后所选"部门技能"前十</li>
              </ul>
            </template>
            <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
          </a-popover>
        </template>
        <CapabilityCharts :dataArr="capabilityChartData.skills" :config="capabilityChartColors.skills"></CapabilityCharts>
      </Card>
      <Card class="w4h6" title="证书汇总">
        <img class="zoom-img" @click="zoomEchart('2')" src="@/assets/iconfont/zoom.png" alt="zoom" />
        <template #icon>
          <a-popover placement="topLeft">
            <template #content>
              <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                <li>该图表默认展示人员排名前十的证书，如有筛选，则按照筛选条件过滤展示前十</li>
              </ul>
            </template>
            <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
          </a-popover>
        </template>
        <CapabilityCharts :dataArr="capabilityChartData.certifications" :config="capabilityChartColors.certifications">
        </CapabilityCharts>
      </Card>
      <Card class="w2h6" title="行业经验汇总">
        <img class="zoom-img" @click="zoomEchart('3')" src="@/assets/iconfont/zoom.png" alt="zoom" />
        <CapabilityCharts title="行业经验汇总" :dataArr="capabilityChartData.industryLines" :config="capabilityChartColors.industryLines">
        </CapabilityCharts>
      </Card>
    </div>
    <div class="column mt24" style="margin-bottom:50px;">
      <Card class="w10h4" title="Role汇总">
        <img class="zoom-img" @click="zoomEchart('4')" src="@/assets/iconfont/zoom.png" alt="zoom" />
        <template #icon>
          <a-popover placement="topLeft">
            <template #content>
              <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                <li>该图表展示的Role为全量，如有筛选，则按照筛选条件过滤人数</li>
              </ul>
            </template>
            <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
          </a-popover>
        </template>
        <CapabilityCharts :dataArr="capabilityChartData.roles" :config="capabilityChartColors.roles"></CapabilityCharts>
      </Card>
    </div>
    <!-- 全屏弹窗 部门技能汇总 -->
    <a-modal v-model:visible="visible1" footer="" title="" @ok="handleOk" width="100%" wrapClassName="full-modal">
      <a-carousel dots="false" arrows>
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
        <div>
          <!-- 部门技能汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="部门技能汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>若未筛选“部门技能”，该图表展示过滤后排名前十的技能</li>
                    <li>若筛选“部门技能”&lt;10，则图表优先左侧展示符合筛选条件的人数，且将满足以上条件人员对应的其他技能按照排名在图表中右侧补位</li>
                    <li>若筛选“部门技能”&ge;10，则仅展示排序后所选"部门技能"前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.skills" :config="capabilityChartColors.skills">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 证书汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="证书汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表默认展示人员排名前十的证书，如有筛选，则按照筛选条件过滤展示前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.certifications"
              :config="capabilityChartColors.certifications">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 行业经验汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w2h6" title="行业经验汇总">
            <CapabilityCharts :dataArr="capabilityChartData.industryLines" :config="capabilityChartColors.industryLines">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- Role汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w10h4" title="Role汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表展示的Role为全量，如有筛选，则按照筛选条件过滤人数</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.roles" :config="capabilityChartColors.roles">
            </CapabilityCharts>
          </Card>
        </div>
      </a-carousel>
    </a-modal>
    <!-- 全屏弹窗 证书汇总 -->
    <a-modal v-model:visible="visible2" footer="" title="" @ok="handleOk" width="100%" wrapClassName="full-modal">
      <a-carousel dots="false" arrows>
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
        <div>
          <!-- 证书汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="证书汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表默认展示人员排名前十的证书，如有筛选，则按照筛选条件过滤展示前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.certifications"
              :config="capabilityChartColors.certifications">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 行业经验汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w2h6" title="行业经验汇总">
            <CapabilityCharts :dataArr="capabilityChartData.industryLines" :config="capabilityChartColors.industryLines">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- Role汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w10h4" title="Role汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表展示的Role为全量，如有筛选，则按照筛选条件过滤人数</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.roles" :config="capabilityChartColors.roles">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 部门技能汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="部门技能汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>若未筛选“部门技能”，该图表展示过滤后排名前十的技能</li>
                    <li>若筛选“部门技能”&lt;10，则图表优先左侧展示符合筛选条件的人数，且将满足以上条件人员对应的其他技能按照排名在图表中右侧补位</li>
                    <li>若筛选“部门技能”&ge;10，则仅展示排序后所选"部门技能"前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.skills" :config="capabilityChartColors.skills">
            </CapabilityCharts>
          </Card>
        </div>
      </a-carousel>
    </a-modal>
    <!-- 全屏弹窗 行业经验汇总 -->
    <a-modal v-model:visible="visible3" footer="" title="" @ok="handleOk" width="100%" wrapClassName="full-modal">
      <a-carousel dots="false" arrows>
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
        <div>
          <!-- 行业经验汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w2h6" title="行业经验汇总">
            <CapabilityCharts :dataArr="capabilityChartData.industryLines" :config="capabilityChartColors.industryLines">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- Role汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w10h4" title="Role汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表展示的Role为全量，如有筛选，则按照筛选条件过滤人数</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.roles" :config="capabilityChartColors.roles">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 部门技能汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="部门技能汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>若未筛选“部门技能”，该图表展示过滤后排名前十的技能</li>
                    <li>若筛选“部门技能”&lt;10，则图表优先左侧展示符合筛选条件的人数，且将满足以上条件人员对应的其他技能按照排名在图表中右侧补位</li>
                    <li>若筛选“部门技能”&ge;10，则仅展示排序后所选"部门技能"前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.skills" :config="capabilityChartColors.skills">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 证书汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="证书汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表默认展示人员排名前十的证书，如有筛选，则按照筛选条件过滤展示前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.certifications"
              :config="capabilityChartColors.certifications">
            </CapabilityCharts>
          </Card>
        </div>
      </a-carousel>
    </a-modal>
    <!-- 全屏弹窗 Role汇总 -->
    <a-modal v-model:visible="visible4" footer="" title="" @ok="handleOk" width="100%" wrapClassName="full-modal">
      <a-carousel dots="false" arrows>
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
        <div>
          <!-- Role汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w10h4" title="Role汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表展示的Role为全量，如有筛选，则按照筛选条件过滤人数</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.roles" :config="capabilityChartColors.roles">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 部门技能汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="部门技能汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>若未筛选“部门技能”，该图表展示过滤后排名前十的技能</li>
                    <li>若筛选“部门技能”&lt;10，则图表优先左侧展示符合筛选条件的人数，且将满足以上条件人员对应的其他技能按照排名在图表中右侧补位</li>
                    <li>若筛选“部门技能”&ge;10，则仅展示排序后所选"部门技能"前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.skills" :config="capabilityChartColors.skills">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 证书汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w4h6" title="证书汇总">
            <template #icon>
              <a-popover placement="topLeft">
                <template #content>
                  <ul style="list-style-type: disc;padding-left: 12px;margin-bottom: -3px">
                    <li>该图表默认展示人员排名前十的证书，如有筛选，则按照筛选条件过滤展示前十</li>
                  </ul>
                </template>
                <QuestionCircleOutlined class="question-color" :style="{ fontSize: '14px', position: 'relative' }" />
              </a-popover>
            </template>
            <CapabilityCharts :dataArr="capabilityChartData.certifications"
              :config="capabilityChartColors.certifications">
            </CapabilityCharts>
          </Card>
        </div>
        <div>
          <!-- 行业经验汇总 -->
          <Card style="height: 500px; width: 94%; margin: 0 auto;" class="w2h6" title="行业经验汇总">
            <CapabilityCharts :dataArr="capabilityChartData.industryLines" :config="capabilityChartColors.industryLines">
            </CapabilityCharts>
          </Card>
        </div>
      </a-carousel>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Card from "@/components/Card";
import CapabilityCharts from "@/views/home/ResourceManagement/RM/Capability/CapabilityCharts";
import { QuestionCircleOutlined, LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
// import { color } from "echarts";

export default {
  name: "CapabilityManagement",
  components: {
    CapabilityCharts,
    Card,
    QuestionCircleOutlined,
    LeftCircleOutlined,
    RightCircleOutlined
  },
  props: {
    capabilityChartData: {
      type: Object,
      default: () => { },
    },
    capabilityChartColors: {
      type: Object,
      default: () => { },
    },
  },
  setup() {
    let slider = ref('1')
    const visible1 = ref(false);
    const visible2 = ref(false);
    const visible3 = ref(false);
    const visible4 = ref(false);
    const zoomEchart = (index) => {
      if (index === '1') {
        visible1.value = true;
      } else if (index === '2') {
        visible2.value = true;
      } else if (index === '3') {
        visible3.value = true;
      } else if (index === '4') {
        visible4.value = true;
      }
    }
    const handleOk = () => {
      visible1.value = false;
      visible2.value = false;
      visible3.value = false;
      visible4.value = false;
    };
    const handleGoTo = (slideNumber, dontAnimate) => {
      console.log(slideNumber, dontAnimate);
    }
    onMounted(() => { })
    return {
      zoomEchart,
      handleOk,
      visible1,
      visible2,
      visible3,
      visible4,
      handleGoTo,
      slider
    }
  }
}
</script>

<style scoped lang="less">
.personNumInfo {
  font-size: 16px;
  display: flex;
  justify-content: space-between;

  .feature-line {
    text-align: right;
  }
}

.capability-charts-content {
  height: auto;
  display: flex;
  //overflow: scroll;
  flex: 1;
  flex-direction: column;

  .column {
    height: 54vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mt3 {
    margin-top: 3px;
  }

  .w4h6 {
    width: 36%;
    height: 500px;
    position: relative;

    .zoom-img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .w2h6 {
    width: 25%;
    position: relative;

    .zoom-img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .w10h4 {
    width: 100%;
    position: relative;

    .zoom-img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.question-color {
  color: #3182ce;
}

.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(500vh);
  }

  .ant-modal-body {
    flex: 1;
  }
}

// 轮播图
/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 100%;
  line-height: 60px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.6;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.8;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
// :deep .card{
//   box-shadow: 0px 0px 0.52083vw rgba(0, 0, 0, 0)!important;
// }
</style>