<template>
  <div ref="chart" style="width: 100%; height: 100%" v-resize="onResize"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "TaskBarChart",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      yData: [],
    };
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setData() {
      this.yData = this.config;
    },
    setOption() {
      let adapterFont = (this.$refs.chart.offsetWidth / 100) * 3;
      const colors = this.yData.map(({ color }) => color);
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: adapterFont * 0.55,
          },
          formatter: "{b}: {c}",
        },
        grid: {
          top: "3%",
          left: "3%",
          right: adapterFont * 10,
          bottom: "0",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: { fontSize: adapterFont / 1.5, color: "#787878" },
        },
        yAxis: {
          show: this.dataArr.length > 0,
          type: "category",
          inverse: true,
          data: this.yData.map(({ label }) => label),
          // 设置成 0 强制显示所有标签 避免y轴显示不全
          axisLabel: { interval: 0, fontSize: adapterFont / 1.8 },
        },
        legend: {
          show: true,
          orient: "vertical",
          right: -14,
          top: "3%",
          icon: "circle",
          itemWidth: adapterFont / 2.5,
          itemHeight: adapterFont / 2.5,
          itemGap: 5,
          textStyle: { fontSize: adapterFont / 2 },
          selectedMode: true,
        },
        series: [
          ...this.yData.map((item, index) => {
            const arr = Array(index + 1).fill(undefined);
            this.dataArr.forEach((_item, _index) => {
              if (_index === index && _item !== undefined) {
                arr[index] = _item;
              }
            });
            return {
              name: item.label,
              type: "bar",
              data: arr.map((item) => (item === undefined ? "-" : item)),
              barWidth: "80%",
              barGap: "-100%",
              itemStyle: {
                color: colors[index],
              },
              label: {
                show: true,
                position: "right",
                color: "#bbbbbb",
                formatter: "{c}人",
                fontSize: adapterFont / 1.7,
              },
              textStyle: { fontSize: adapterFont / 2.5 },
            };
          }),
        ],
      });
    },
    onResize() {
      this.chart.resize();
    },
  },
  computed: {
    dataArr() {
      return this.yData.map(({ key }) => this.data[key]);
    },
  },
  watch: {
    dataArr: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
    config: {
      deep: true,
      handler() {
        this.setData();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.setData();
    this.chart.on("click", ({ dataIndex }) => {
      this.$emit("itemClick", this.yData[dataIndex].key);
    });
    this.chart.on("legendselectchanged", (params) => {
      const selectData = params.selected;
      let yData = this.config;
      for (let i in selectData) {
        if (!selectData[i]) {
          yData = yData.filter((item) => item.label !== i);
        }
      }
      this.yData = yData;
      this.setOption();
    });
  },
};
</script>
