<template>
  <div ref="chart" style="width: 100%; height: 100%" v-resize="onResize"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "AssignmentTypeBarChart",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      let colors = ["#77CAED", "#ADDFF4"];
      let adapterFont = (this.$refs.chart.offsetWidth / 100) * 3;
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: adapterFont * 0.55,
          },
          formatter: "{b}: {c}",
        },
        grid: {
          top: "3%",
          left: adapterFont * 4,
          right: adapterFont * 10,
          bottom: "0",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          show: this.dataArr.length > 0,
          type: "category",
          inverse: true,
          data: this.config.map(({ label }) => label),
          // 设置成 0 强制显示所有标签 避免y轴显示不全
          axisLabel: { interval: 0, fontSize: adapterFont / 1.8 },
        },
        legend: {
          show: true,
          orient: "vertical",
          right: 42,
          top: "3%",
          icon: "circle",
          itemWidth: adapterFont / 2.5,
          itemHeight: adapterFont / 2.5,
          itemGap: 5,
          textStyle: { fontSize: adapterFont / 2 },
          selectedMode: true,
        },
        series: [
          ...this.config.map((item, index) => {
            const arr = Array(index + 1).fill(undefined);
            this.dataArr.forEach((_item, _index) => {
              if (_index === index && _item !== undefined) {
                arr[index] = _item;
              }
            });
            return {
              name: item.label,
              type: "bar",
              data: arr.map((item) => (item === undefined ? "-" : item)),
              barWidth: "20%",
              barGap: "-100%",
              itemStyle: {
                color: colors[index],
              },
              label: {
                show: true,
                position: "right",
                color: "#bbbbbb",
                formatter: "{c}人",
                fontSize: adapterFont / 1.7,
              },
              textStyle: { fontSize: adapterFont / 2.5 },
            };
          }),
        ],
      });
    },
    onResize() {
      this.chart.resize();
    },
  },
  computed: {
    dataArr() {
      return this.config.map(({ key }) =>
        this.data[key] ? this.data[key] : "-"
      );
    },
  },
  watch: {
    dataArr: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.chart.on("click", ({ dataIndex }) => {
      this.$emit("itemClick", this.config[dataIndex].key);
    });
  },
};
</script>
