<template>
  <div
    ref="chart"
    style="width: 100%; height: 100%"
    v-resize="adapterScreen"
  ></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "AvailabilityPieChart",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },

    setOption() {
      this.chart.setOption({
        avoidLabelOverlap: false,
        color: ["#5A99D4", "#FDE9B4", "#B0DFD2"],
        series: [
          {
            type: "pie",
            radius: "74%",
            center: ["38%", "48%"],
            data: this.dataArr,
            label: {
              normal: {
                formatter: "{b} {c}人，{d}%",
              },
            },
            textStyle: { color: "#787878", fontSize: "16" },
            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              scale: true,
              scaleSize: 10,
            },
          },
        ],
      });
    },
    // onResize() {
    //   this.chart.resize();
    // },
    // 适配
    adapterScreen() {
      let adapterFont = (this.$refs.chart.offsetWidth / 100) * 3;
      const adpOption = {
        tooltip: {
          trigger: "item",
          textStyle: {
            fontSize: adapterFont * 0.55,
          },
          formatter: (params) => {
            const str =
              "status：" +
              params.data.name +
              "</br>" +
              "人数：" +
              params.data.value +
              "</br>" +
              "所占总人数百分比：" +
              params.percent +
              "%" +
              "</br>";
            return str;
          },
        },
        legend: {
          show: true,
          orient: "vertical",
          right: 38,
          top: "3%",
          icon: "circle",
          itemWidth: adapterFont / 2.5,
          itemHeight: adapterFont / 2.5,
          itemGap: 5,
          textStyle: { fontSize: adapterFont / 2 },
          selectedMode: true,
        },
        series: [
          {
            label: {
              fontSize: adapterFont / 1.7,
            },
            textStyle: { fontSize: adapterFont / 1.7 },
            emphasis: {
              itemStyle: {
                shadowBlur: adapterFont / 2,
                shadowOffsetX: 0,
              },
            },
          },
        ],
      };
      this.chart.setOption(adpOption);
      this.chart.resize();
    },
  },
  computed: {
    dataArr() {
      return this.config.map(({ key, label }) => ({
        key,
        value: this.data[key] === 0 ? null : this.data[key],
        name: label,
      }));
    },
  },
  watch: {
    dataArr: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.adapterScreen();
    this.chart.on("click", (params) => {
      this.$emit("itemClick", params.data.key);
    });
  },
};
</script>
